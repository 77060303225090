import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { ChannelTypeEnum } from '@common-src/entity-model/channel-entity';
import TemplateService from '@common-src/service/template';
import ChannelService from '@common-src/service/channel';

export enum CorpMsgTypeEnum {
    MARKDOWN= 'MARKDOWN', // MarkDown消息
    TEXT = 'TEXT', // 文本消息
    MINIPROGRAM_NOTICE = 'MINIPROGRAM_NOTICE', // 小程序消息
    TEXTCARD = 'TEXTCARD', // 文本卡片消息
}

// 企业微信消息类型
export const CorpMsgTypeList = [
    {
        name: 'Markdown',
        value: CorpMsgTypeEnum.MARKDOWN
    }, {
        name: '文本消息',
        value: CorpMsgTypeEnum.TEXT
    }, {
        name: '小程序消息',
        value: CorpMsgTypeEnum.MINIPROGRAM_NOTICE
    }, {
        name: '文本卡片消息',
        value: CorpMsgTypeEnum.TEXTCARD
    }
];
// 企业微信机器人消息类型
export const CorpRobotMsgTypeList = [
    {
        name: 'Markdown',
        value: CorpMsgTypeEnum.MARKDOWN
    }, {
        name: '文本消息',
        value: CorpMsgTypeEnum.TEXT
    }
];

const InvisibleFunc = (...ChannelList) => (model:any, controlList) => {
    if (!controlList) {
        return !_.some(ChannelList, item => model.channelType === item);
    } else {
        const { options, value } = _.find(controlList, item => item.key === 'channelId');
        const selectChanneltype = _.get(_.find(options, oItem => oItem.value === value), 'channelType');
        return !_.some(ChannelList, item => selectChanneltype === item);
    }
};

const TplInvisibleFunc = (val) => (model:any, controlList) => {
    if (!controlList) {
        return model.channelType !== ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT || model.type !== val;
    } else {
        const { options, value } = _.find(controlList, item => item.key === 'channelId');
        const selectChanneltype = _.get(_.find(options, oItem => oItem.value === value), 'channelType');
        const selectType = _.get(_.find(controlList, item => item.key === 'type'), 'value');
        return selectChanneltype !== ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT || selectType !== val;
    }
};

const MiniInvisibleFunc = (val) => (model:any, controlList) => {
    if (!controlList) {
        if (val === 'page') {
            return !(model.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION && model.corpMsgType === CorpMsgTypeEnum.TEXTCARD);
        } else if (val === 'title') {
            return !(model.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION && (model.corpMsgType === CorpMsgTypeEnum.MINIPROGRAM_NOTICE || model.corpMsgType === CorpMsgTypeEnum.TEXTCARD));
        } else return model.channelType !== ChannelTypeEnum.CORP_WE_CHAT_APPLICATION || model.corpMsgType !== CorpMsgTypeEnum.MINIPROGRAM_NOTICE;
    } else {
        const { options, value } = _.find(controlList, item => item.key === 'channelId');
        const selectChanneltype = _.get(_.find(options, oItem => oItem.value === value), 'channelType');
        const selectType = _.get(_.find(controlList, item => item.key === 'corpMsgType'), 'value');
        if (val === 'page') {
            return !(selectChanneltype === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION && selectType === CorpMsgTypeEnum.TEXTCARD);
        } else if (val === 'title') {
            return !(selectChanneltype === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION && (selectType === CorpMsgTypeEnum.MINIPROGRAM_NOTICE || selectType === CorpMsgTypeEnum.TEXTCARD));
        } else return selectChanneltype !== ChannelTypeEnum.CORP_WE_CHAT_APPLICATION || selectType !== CorpMsgTypeEnum.MINIPROGRAM_NOTICE;
    }
};

const ContentInvisibleFunc = (model:any, controlList) => {
    if (!controlList) {
        return !(model.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION && model.corpMsgType !== CorpMsgTypeEnum.MINIPROGRAM_NOTICE || model.channelType === ChannelTypeEnum.CORP_WE_CHAT_ROBOT);
    } else {
        const { options, value } = _.find(controlList, item => item.key === 'channelId');
        const selectChanneltype = _.get(_.find(options, oItem => oItem.value === value), 'channelType');
        const selectType = _.get(_.find(controlList, item => item.key === 'corpMsgType'), 'value');
        return !(selectChanneltype === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION && selectType !== CorpMsgTypeEnum.MINIPROGRAM_NOTICE || selectChanneltype === ChannelTypeEnum.CORP_WE_CHAT_ROBOT);
    }
};

export class TemplateEntityModel extends BaseEntityModel {
    static baseUrl = `${MESSAGE_BASE_REQUEST_PATH}/template`;

    templateConfig:any = {};
    channelName: string = undefined;

    @FormControl({
        label: '模板名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    templateName: string = undefined;

    @FormControl({
        label: '消息渠道',
        type: FormControlType.SELECT,
        optionsPromise: TemplateService.getChannelList,
        required: true,
        cascad: true,
        readonly: true,
        change: (_formControlList, formControl, formModel) => {
            if (formControl.options) {
                const { channelType } = _.find(formControl.options, item => item.value === formModel.channelId);
                formModel.channelType = channelType;
            }
        }
    } as FormControlOptionModel)
    channelId: string = undefined;
    channelType: ChannelTypeEnum = undefined;

    @FormControl({
        label: '消息标题',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(
            ChannelTypeEnum.WECHAT_SERVICE,
            ChannelTypeEnum.MESSAGE_CENTER,
            ChannelTypeEnum.LARGE_SCREEN,
            ChannelTypeEnum.DING_ROBOT,
            ChannelTypeEnum.FEISHU_ROBOT,
            ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    title: string = undefined;

    @FormControl({
        label: '消息类型',
        type: FormControlType.SELECT,
        cascad: true,
        options: [{ name: '消息卡片-模板', value: 'template' }, { name: '消息卡片-json', value: 'json' }],
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT)
    } as FormControlTextModel)
    type: string = undefined;

    @FormControl({
        label: '消息类型',
        type: FormControlType.SELECT,
        cascad: true,
        options: CorpMsgTypeList,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.CORP_WE_CHAT_APPLICATION)
    } as FormControlTextModel)
    corpMsgType: string = undefined;

    @FormControl({
        label: '消息类型',
        type: FormControlType.SELECT,
        cascad: true,
        options: CorpRobotMsgTypeList,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.CORP_WE_CHAT_ROBOT)
    } as FormControlTextModel)
    corpRobotMsgType: string = undefined;

    @FormControl({
        label: '消息标题',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: TplInvisibleFunc('json')
    } as FormControlTextModel)
    jsonTitle: string = undefined;

    @FormControl({
        label: '消息内容',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: TplInvisibleFunc('json')
    } as FormControlTextModel)
    jsonContent: string = undefined;

    @FormControl({
        label: 'AppId',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: MiniInvisibleFunc('')
    } as FormControlTextModel)
    appId: string = undefined;

    @FormControl({
        label: '消息标题',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: MiniInvisibleFunc('title')
    } as FormControlTextModel)
    corpTitle: string = undefined;

    @FormControl({
        label: '消息内容',
        type: FormControlType.TEXT_AREA,
        required: true,
        invisibleFunction: ContentInvisibleFunc
    } as FormControlTextModel)
    content: string = undefined;

    @FormControl({
        label: '消息描述',
        type: FormControlType.TEXT,
        invisibleFunction: MiniInvisibleFunc('')
    } as FormControlTextModel)
    description: string = undefined;

    @FormControl({
        label: '第一个内容放大',
        type: FormControlType.RADIO_GROUP,
        options: [
            { name: '是', value: true },
            { name: '否', value: false }
        ],
        invisibleFunction: MiniInvisibleFunc('')
    } as FormControlOptionModel)
    emphasisFirstItem: boolean = false;

    @FormControl({
        label: '消息内容',
        type: FormControlType.FORM_CORPMSG,
        layout: { labelCol: 5, wrapperCol: 16, width: '100% ' },
        msgKey: 'key',
        msgValue: 'value',
        required: true,
        invisibleFunction: MiniInvisibleFunc('')
    })
    corpTemplateVariable: Array<any> = [];

    @FormControl({
        label: '跳转地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: MiniInvisibleFunc('page')
    } as FormControlTextModel)
    page: string = undefined;

    @FormControl({
        label: '跳转地址',
        type: FormControlType.TEXT,
        invisibleFunction: MiniInvisibleFunc('')
    } as FormControlTextModel)
    Minipage: string = undefined;

    @FormControl({
        label: '消息类型',
        type: FormControlType.SELECT,
        optionsPromise: ChannelService.msgTypeList,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.FEISHU_ROBOT)
    } as FormControlTextModel)
    msgType: string = undefined;

    @FormControl({
        label: '卡片消息模板ID',
        type: FormControlType.TEXT,
        required: true,
        deleteAction: true,
        invisibleFunction: TplInvisibleFunc('template')
    } as FormControlTextModel)
    templateId: string = undefined;

    @FormControl({
        label: '消息内容',
        type: FormControlType.FORM_TEMPLATE,
        layout: { labelCol: 5, wrapperCol: 16, width: '100% ' },
        required: true,
        fsParamKey: 'fsParamKey',
        fsParamName: 'fsParamName',
        bindingKey: 'bindingKey',
        invisibleFunction: TplInvisibleFunc('template')
    })
    templateVariable: Array<any> = [];

    @FormControl({
        label: '发送方邮箱',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    username: string = undefined;
    @FormControl({
        label: '邮箱授权码',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    password: string = undefined;
    @FormControl({
        label: '邮件服务器地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    host: string = undefined;
    @FormControl({
        label: '邮件服务器端口',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.EMAIL)
    } as FormControlTextModel)
    port: string = undefined;

    // @FormControl({
    //     label: '业务标识',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     invisibleFunction: InvisibleFunc(ChannelTypeEnum.MESSAGE_CENTER)
    // } as FormControlTextModel)
    // centerId: string = undefined;

    // @FormControl({
    //     label: '业务类型',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     invisibleFunction: InvisibleFunc(ChannelTypeEnum.MESSAGE_CENTER)
    // } as FormControlTextModel)
    // bizType: string = undefined;

    @FormControl({
        label: '微信模板ID',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    } as FormControlTextModel)
    weChatTemplateId: string = undefined;

    @FormControl({
        label: '跳转地址',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    } as FormControlTextModel)
    pagePath: string = undefined;

    @FormControl({
        label: '参数配置',
        type: FormControlType.FORM_DOUBLE,
        required: true,
        labelName: 'label',
        valueName: 'value',
        invisibleFunction: InvisibleFunc(
            ChannelTypeEnum.MESSAGE_CENTER,
            ChannelTypeEnum.LARGE_SCREEN,
            ChannelTypeEnum.FEISHU_ROBOT
        )
    })
    template: Array<any> = [];

    @FormControl({
        label: '参数配置',
        type: FormControlType.FORM_TRIPLE,
        required: true,
        labelName: 'weChatParamName',
        valueName: 'bindingKey',
        keyWord: 'weChatParamKey',
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.WECHAT_SERVICE)
    })
    weChatTemplate: Array<any> = [];

    @FormControl({
        label: '内容',
        type: FormControlType.TEXT_AREA,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.DING_ROBOT)
    } as FormControlTextModel)
    text: string = undefined;

    @FormControl({
        label: '简要内容',
        type: FormControlType.TEXT_AREA,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.MESSAGE_CENTER)
    } as FormControlTextModel)
    summary: string = undefined;

    @FormControl({
        label: '消息签名',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    signName: string = undefined;

    @FormControl({
        label: '模板码',
        type: FormControlType.TEXT,
        required: true,
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    templateCode: string = undefined;

    @FormControl({
        label: '模板内容',
        type: FormControlType.FORM_DOUBLE,
        required: true,
        labelName: 'smsParamName',
        valueName: 'bindingKey',
        invisibleFunction: InvisibleFunc(ChannelTypeEnum.ALIYUN_SMS)
    } as FormControlTextModel)
    smsTemplate: Array<any> = [];

    @FormControl({
        label: '备注',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '模板名称',
                dataIndex: 'templateName',
                scopedSlots: { customRender: 'name' },
                width: 400
            },
            {
                title: '消息标题',
                dataIndex: 'title',
                customRender: (_text, record) => {
                    return record.title ? record.title : '--';
                }
            },
            {
                title: '消息渠道',
                dataIndex: 'channelName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 300
            }
        ];
    }
    toModel(json): any {
        super.toModel(json);
        this.username = this.templateConfig.username;
        this.password = this.templateConfig.password;
        this.host = this.templateConfig.host;
        this.port = this.templateConfig.port;
        this.weChatTemplateId = this.templateConfig.weChatTemplateId;
        this.template = _.filter(this.templateConfig.template, item => item.label !== '消息类型');
        this.weChatTemplate = this.templateConfig.weChatTemplate;
        this.text = this.templateConfig.text;
        this.summary = this.templateConfig.summary;
        this.pagePath = this.templateConfig.pagePath;
        this.smsTemplate = this.templateConfig.smsTemplate;
        this.signName = this.templateConfig.signName;
        this.templateCode = this.templateConfig.templateCode;
        this.page = this.templateConfig.page;
        this.Minipage = this.templateConfig.page;
        this.description = this.templateConfig.description;
        this.appId = this.templateConfig.appId;
        this.content = this.templateConfig.content;
        this.emphasisFirstItem = this.templateConfig.emphasisFirstItem;
        this.description = this.templateConfig.description;
        if (this.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION) {
            this.corpMsgType = this.templateConfig.msgType;
            this.corpTitle = this.templateConfig.title;
            this.corpTemplateVariable = _.get(this.templateConfig, 'data.templateVariable') || [];
        } else if (this.channelType === ChannelTypeEnum.CORP_WE_CHAT_ROBOT) {
            this.corpRobotMsgType = this.templateConfig.msgType;
        }
        this.msgType = _.get(_.findLast(this.templateConfig.template, ['label', '消息类型']), 'value');
        this.templateId = _.get(this.templateConfig, 'data.templateId');
        this.templateVariable = _.get(this.templateConfig, 'data.templateVariable');
        this.type = _.get(this.templateConfig, 'type');
        this.jsonTitle = _.get(_.find(_.get(this.templateConfig, 'data.templateVariable'), item => item.fsParamName === '标题'), 'bindingKey');
        this.jsonContent = _.get(_.find(_.get(this.templateConfig, 'data.templateVariable'), item => item.fsParamName === '内容'), 'bindingKey');

        return this;
    }

    toService() {
        const data: any = super.toService();
        data.templateConfig.username = data.username;
        data.templateConfig.password = data.password;
        data.templateConfig.host = data.host;
        data.templateConfig.port = data.port;
        data.templateConfig.weChatTemplateId = data.weChatTemplateId;
        data.templateConfig.template = data.template;
        data.templateConfig.weChatTemplate = data.weChatTemplate;
        data.templateConfig.text = data.text;
        data.templateConfig.summary = data.summary;
        data.templateConfig.pagePath = data.pagePath;
        data.templateConfig.smsTemplate = data.smsTemplate;
        data.templateConfig.signName = data.signName;
        data.templateConfig.templateCode = data.templateCode;
        data.templateConfig.appId = data.appId;
        data.templateConfig.title = data.corpTitle;
        data.templateConfig.content = data.content;
        data.templateConfig.description = data.description;
        if (data.channelType === ChannelTypeEnum.FEISHU_ROBOT) {
            data.templateConfig.template = [...data.template, { label: '消息类型', value: data.msgType }];
        } else if (data.channelType === ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT && data.type === 'template') {
            data.templateConfig = {
                data: {
                    templateId: data.templateId,
                    templateVariable: data.templateVariable
                },
                msgType: 'interactive',
                type: data.type
            };
        } else if (data.channelType === ChannelTypeEnum.FEI_SHU_APPLICATION_ROBOT && data.type === 'json') {
            data.templateConfig = {
                data: {
                    templateId: data.templateId,
                    templateVariable: [
                        {
                            bindingKey: data.jsonTitle,
                            fsParamKey: 'header',
                            fsParamName: '标题'
                        },
                        {
                            bindingKey: data.jsonContent,
                            fsParamKey: 'content',
                            fsParamName: '内容'
                        }
                    ]
                },
                msgType: 'interactive',
                type: data.type
            };
        } else if (data.channelType === ChannelTypeEnum.CORP_WE_CHAT_APPLICATION) {
            data.templateConfig.msgType = data.corpMsgType;
            if (data.corpMsgType === CorpMsgTypeEnum.MINIPROGRAM_NOTICE) {
                data.title = data.corpTitle;
                data.templateConfig.page = data.Minipage;
                data.templateConfig.emphasisFirstItem = data.emphasisFirstItem;
                data.templateConfig.data = {
                    templateVariable: data.corpTemplateVariable
                };
            } else if (data.corpMsgType === CorpMsgTypeEnum.TEXTCARD) {
                data.templateConfig.page = data.page;
                data.title = data.corpTitle;
            }
        } else if (data.channelType === ChannelTypeEnum.CORP_WE_CHAT_ROBOT) {
            data.templateConfig.msgType = data.corpRobotMsgType;
        }

        delete data.username;
        delete data.password;
        delete data.host;
        delete data.port;
        delete data.weChatTemplateId;
        delete data.template;
        delete data.weChatTemplate;
        delete data.text;
        delete data.summary;
        delete data.pagePath;
        delete data.channelName;
        delete data.smsTemplate;
        delete data.signName;
        delete data.templateCode;
        delete data.msgType;
        delete data.templateId;
        delete data.templateVariable;
        delete data.type;
        delete data.jsonTitle;
        delete data.jsonContent;
        delete data.appId;
        delete data.corpMsgType;
        delete data.corpRobotMsgType;
        delete data.content;
        delete data.corpTitle;
        delete data.emphasisFirstItem;
        delete data.description;
        delete data.Minipage;
        delete data.page;
        delete data.corpTemplateVariable;
        return data;
    }
}

export class TemplateQueryModel extends QueryPageModel {
    @QueryControl({
        label: '渠道类型',
        type: QueryControlType.SELECT,
        // options: ChannelTypeList,
        optionsPromise: ChannelService.channelTypeList,
        hasAllOption: true,
        span: 6
    })
    channelType: ChannelTypeEnum = undefined;

    @QueryControl({
        label: '模板名称',
        type: QueryControlType.TEXT,
        span: 6
    })
    templateName: string = undefined;

    toService() {
        // return { params: this.name };
        return super.getParams();
    }
}
